import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import axios from "axios";
import { useConfig } from "@peracto/peracto-config";

let Icon = () => (
    <div className="flex py-10 justify-center items-center w-full">
        <FontAwesomeIcon icon={faList} size="6x" />
    </div>
);

const MenuItemsComponent = ({
    state: {
        menuItem,
        menuItemPath,
        layout = "grid",
        fallbackImage,
        categoryPath = "category",
    },
    isEditMode,
}) => {
    const config = useConfig();
    const API_URL = config.get("api");
    const [menuItemData, setMenuItemData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);

    useEffect(() => {
        if (menuItemPath) {
            const loadMenuItems = async () => {
                setLoadingError(false);
                const path =
                    menuItemPath[0] === "/"
                        ? menuItemPath.substr(1)
                        : menuItemPath;
                try {
                    axios
                        .get(`${API_URL}/menu-items-children-by-path/${path}`)
                        .then((res) => {
                            const data = res.data && res.data["hydra:member"];

                            if (data) {
                                setMenuItemData(data);
                                setIsLoading(false);
                            }
                        })
                        .catch((error) => {
                            setLoadingError(true);
                            return Promise.reject(error);
                        });
                } catch (e) {
                    console.error(e);
                    setLoadingError(true);
                }
            };

            loadMenuItems();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuItemPath]);

    if (loadingError) {
        // this has padding so the element is at least clickable so it can be selected and edited
        return <div className="py-10 w-full"></div>;
    }

    const isGrid = layout === "grid";

    const inStorefront = !window?.location?.pathname.includes("/content/edit/");

    if (isGrid) {
        return (
            <div
                className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[30px] items-start`}
            >
                {menuItemPath ? (
                    <>
                        {menuItemData?.length > 0
                            ? menuItemData.map((menuItem, index) => {
                                  if (
                                      !menuItem.path ||
                                      menuItem.itemType === "branch"
                                  ) {
                                      return null;
                                  }
                                  let path =
                                      !menuItem.path.startsWith("http") &&
                                      menuItemPath[0] !== "/"
                                          ? `/${menuItem.path}`
                                          : menuItem.path;

                                  if (
                                      menuItem.itemType === "category" &&
                                      !menuItem.path.startsWith(
                                          `/${categoryPath}/`
                                      )
                                  ) {
                                      path = `/${categoryPath}${path}`;
                                  }

                                  const image =
                                      menuItem.content?.image ||
                                      menuItem.image ||
                                      fallbackImage ||
                                      "/images/placeholder.jpg";

                                  const decodedImage = image
                                      ?.replaceAll("(", "%28")
                                      ?.replaceAll(")", "%29");

                                  return (
                                      <a
                                          href={path}
                                          key={index}
                                          className="!text-brand-black !no-underline flex flex-col items-center justify-center hover:!underline"
                                      >
                                          <img
                                              src={decodedImage}
                                              alt={menuItem.linkText}
                                              className="rounded-full aspect-square max-w-[160px] max-h-[160px] border border-brand-grey"
                                          />
                                          <p className="text-base font-bold mt-[20px] text-center">
                                              {menuItem.linkText}
                                          </p>
                                      </a>
                                  );
                              })
                            : !isLoading && "No Menu Items"}
                    </>
                ) : inStorefront ? (
                    <></>
                ) : (
                    <Icon />
                )}
            </div>
        );
    } else {
        return (
            <div className={`flex flex-col gap-y-[15px]`}>
                {menuItemPath ? (
                    <>
                        {menuItemData?.length > 0
                            ? menuItemData.map((menuItem, index) => {
                                  if (
                                      !menuItem.path ||
                                      menuItem.itemType === "branch"
                                  ) {
                                      return null;
                                  }
                                  let path =
                                      !menuItem.path.startsWith("http") &&
                                      menuItemPath[0] !== "/"
                                          ? `/${menuItem.path}`
                                          : menuItem.path;

                                  if (
                                      menuItem.itemType === "category" &&
                                      !menuItem.path.startsWith(
                                          `/${categoryPath}/`
                                      )
                                  ) {
                                      path = `/${categoryPath}${path}`;
                                  }

                                  return (
                                      <a
                                          href={path}
                                          key={index}
                                          className="!text-brand-black !no-underline rounded-[20px] py-[15px] px-[20px] border border-brand-grey hover:!underline inline-flex justify-between items-center"
                                      >
                                          <p className="text-base font-bold">
                                              {menuItem.linkText}
                                          </p>
                                          <FontAwesomeIcon
                                              icon={faChevronRight}
                                              className="text-brand-secondary text-xl"
                                          />
                                      </a>
                                  );
                              })
                            : !isLoading && "No Menu Items"}
                    </>
                ) : inStorefront ? (
                    <></>
                ) : (
                    <Icon />
                )}
            </div>
        );
    }
};

export default MenuItemsComponent;
